import { useState } from 'react'
import { useStore } from '@nanostores/react'
import { isSearchOpen } from '@stores/searchStore'

import SearchIcon from '@components/Search/SearchIcon/SearchIcon.jsx'

const NavSearch = () => {
    const [isHovered, setIsHovered] = useState(false)

    const $isSearchOpen = useStore(isSearchOpen || false)

    // // console.log $isSearchOpen in NavSearch', $isSearchOpen)

    return (
        <div className="nav-search relative -top-2 mr-8">
            {/* <input
                placeholder="Search"
                className={`search-input flex items-center w-72 h-8 bg-stone-200 rounded-md border-2 px-3 mr-4 hover:cursor-pointer hover:border-blue-400 hover:border-2  ${
                    isHovered ? 'border-blue-400' : 'border-stone-300'
                }`}
                onClick={() => isSearchOpen.set(!$isSearchOpen)}
            /> */}

            <div
                className="search-icon ml-auto mr-1 absolute cursor-pointer"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={() => isSearchOpen.set(!$isSearchOpen)}
            >
                <SearchIcon width="w-[18px]" />
            </div>
        </div>
    )
}

export default NavSearch
